<script>

import axios from 'axios';
/**
 * Advanced table component
 */
export default {


  data() {
    return {
      vehicleRejectId: null,
      rejectMessage: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: false,
      fields: [
        { key: "car_name", label: 'Vehicle Name', sortable: true },
        { key: "plate_number", label: 'Plate Number', sortable: true },
        { key: "make", label: 'Make', sortable: true },
        { key: "model", label: 'Model', sortable: true },
        { key: "vehicle_status", label: 'Vehicle Status', sortable: true },
        { key: "action" }
      ]
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    vehicles() {
      return this.$store.getters['vehicles/vehiclesGetter']
    },


    rows() {
      return this.vehicles.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.$store.dispatch('vehicles/getVehicles', this.$route.params.id)
    this.totalRows = this.vehicles.length;

  },

  watch: {
    '$route.params.id'(newVal, oldVal) {
      console.log(oldVal)
      if (this.$route.path.includes('document')) {
        console.log('newcall')
        this.$store.dispatch('vehicles/getVehicles', newVal)
      }
    }
  },

  methods: {
    verifyVehicle(documentName, id) {
      axios.post('verify-documents/' + this.$route.params.id, { document: documentName, vehicle_id: id }, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
        }
      }).then((res) => {
        console.log(res)
        this.$store.dispatch('vehicles/verifyVehicle', id)
        this.$bvToast.toast('Verified', {
          variant: 'success',
          solid: true
        })
      }).catch((err) => {
        console.log('error', err)
      })
    },

    rejectVehicle(documentName, id) {

      if (this.rejectMessage) {
        axios.post('reject-documents/' + this.$route.params.id, { document: documentName, vehicle_id: id, message: this.rejectMessage }, {
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
          }
        }).then((res) => {
          this.rejectMessage = null
          console.log(res)
          this.$store.dispatch('vehicles/rejectVehicle', id)
          this.$bvToast.toast('Rejected', {
            variant: 'success',
            solid: true
          })

        }).catch((err) => {
          console.log(err)
        })
      }
      else{
        this.$bvToast.toast('Please enter rejection message first to reject vehicle', {
            variant: 'danger',
            solid: true
          })
      }

    },




    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

  },

};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">All Vehicles</h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="vehicles" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                @filtered="onFiltered">

                <template v-slot:cell(car_name)="{ item }">
                  <div> {{ item.make }} {{ item.model }} </div>
                </template>
                <template v-slot:cell(action)="{ item }">
                 
                  <a v-show="item.vehicle_status !== 'verified'" class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                    title="Accept" @click="verifyVehicle('vehicle_information', item._id)">
                    <i class="ri-checkbox-circle-fill font-size-24" style="color: #1cbb8c"></i>
                  </a>
                  <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                    title="Reject" v-b-modal.reject-vehicle @click="vehicleRejectId = item._id">
                    <i class=" ri-close-circle-fill font-size-24" style=" color: #ff3d60"></i>
                  </a>
                </template>



              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>


            <b-modal id="reject-vehicle" title="Reject Vehicle Message" title-class="font-18" ok-title="Reject"
              @ok="rejectVehicle('vehicle_information', vehicleRejectId)">
              <textarea v-model="rejectMessage" class="form-control" placeholder="Enter Reject Message"></textarea>
            </b-modal>




          </div>
        </div>
      </div>

    </div>

  </div>
</template>